@import url("https://fonts.googleapis.com/css2?family=Google+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.resp-h{
    height: calc(100vh - 5rem);
}

.sidebarcontent-resp-h{
  height: calc(100vh - 7rem);
}

.resp-popup-w{
  width: calc(100vw - 6rem);
}

.resp-processtable-h{
  max-height: calc(100vh - 16.8rem);
}

.resp-search-input-w{
  width: 80%;
}

thead th {
  background-color: #f3f4f6;
  position: sticky;
  top: -1px;
}

.ct{
  overflow-anchor:none;
}

@media (min-width: 768px) {
    .resp-h{
      height: calc(100vh - 5.2rem);
    }
    .sidebarcontent-resp-h{
      height: calc(100vh - 9.5rem);
    }
    .resp-popup-w{
      width: calc(40vw - 1rem);
    }
    .resp-processtable-h{
      max-height: calc(100vh - 16.4rem);
    }
    .resp-search-input-w{
      width: 40%;
    }
}

@media (min-width: 1024px) {
  ::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
    height: 1px;
  }
  ::-webkit-scrollbar-track {
    background: #e0e6ee;
  }
  ::-webkit-scrollbar-thumb {
    background: #075985;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #075985;
  }
  .chatboxscroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    height: 1px;
  }
  .chatboxscroll::-webkit-scrollbar-track {
    background: #e0e6ee;
  }
  .chatboxscroll::-webkit-scrollbar-thumb {
    background: #9ca3af;
    border-radius: 10px;
  }
  .chatboxscroll::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }
}

@media (min-width:1200px){
  .resp-popup-w{
    width: calc(40vw - 16rem);
  }
}

@media (min-width:1536px){
  .resp-search-input-w{
    width: calc(100vw - 80rem);
  }
}

.lefttooltip {
  position: relative;
}

.tooltiptextleft {
  visibility: hidden;
  min-width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 125%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptextleft::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -7.8%;
  margin-top: -3.5%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}

.lefttooltip:hover .tooltiptextleft{
  visibility: visible;
  opacity: 1;
}

.toptooltip {
  position: relative;
}

.tooltiptexttop {
  visibility: hidden;
  min-width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  right: 0%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptexttop::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 10%;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.toptooltip:hover .tooltiptexttop{
  visibility: visible;
  opacity: 1;
}

.bottomtooltip {
  position: relative;
}

.tooltiptextbottom {
  visibility: hidden;
  min-width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: -190%;
  right: 0%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptextbottom::after {
  content: "";
  position: absolute;
  bottom:100%;
  right: 5%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

.bottomtooltip:hover .tooltiptextbottom{
  visibility: visible;
  opacity: 1;
}

.righttooltip {
  position: relative;
}

.tooltiptextright {
  visibility: hidden;
  min-width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 30;
  bottom: 0;
  left: 125%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptextright::after {
  content:  attr(title);
  position: absolute;
  top: 50%;
  right: -7.8%;
  margin-top: -3.5%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}

.righttooltip:hover .tooltiptextright{
  visibility: visible;
  opacity: 1;
}

.list-hyphen ul li::before {
  content: "- ";
}

::-ms-reveal {
  display: none;
}